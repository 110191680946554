var formatRelativeLocale = {
  lastWeek: "eeee 'dernier à' p",
  yesterday: "'hier à' p",
  today: "'aujourd’hui à' p",
  tomorrow: "'demain à' p'",
  nextWeek: "eeee 'prochain à' p",
  other: 'P'
};
var formatRelative = function (token, _date, _baseDate, _options) {
  return formatRelativeLocale[token];
};
export default formatRelative;