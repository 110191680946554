import { config } from './config';

/**
 * DO NOT MODIFY THIS FILE DIRECTLY
 *
 * @see config.ts
 *
 * we only need this file because angular required the production config option to be
 * set at build time and we cannot use our `env` function to set it dynamically.
 */
export const environment = {
  production: true,
  ...config,
};
