import {
  addDays,
  addHours,
  addMonths,
  addWeeks,
  differenceInHours,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfISOWeek,
  endOfISOWeekYear,
  endOfMonth,
  format as formatDate,
  formatRelative as formatRelativeDate,
  getMinutes,
  isBefore,
  isEqual,
  isValid,
  parse,
  parseISO,
  setMinutes,
  startOfDay,
  startOfISOWeek,
  startOfISOWeekYear,
  startOfMonth,
  subDays,
  subMonths,
  subWeeks,
  toDate,
} from 'date-fns';
import isString from 'lodash-es/isString';
import keyBy from 'lodash-es/keyBy';
import range from 'lodash-es/range';
import reduce from 'lodash-es/reduce';

import { dateLocales, DefaultLocale } from '../+authenticated/shared/locale/locale.helper';
import { DateRange, Period, PeriodType, RangeOptions } from './interfaces';

const dayCodes = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];

// copied from ngx-bootstrap to ensure no changes got introduced when removing dependency
// https://github.com/valor-software/ngx-bootstrap/blob/5c526057743588624fc27788082a4846fcebab03/src/chronos/utils/date-compare.ts#L63
export function isSame(date1?: Date, date2?: Date): boolean {
  if (!date1 || !date2) {
    return false;
  }
  return date1.valueOf() === date2.valueOf();
}

export function parseDate(date: string | Date): Date {
  if (isString(date)) {
    return parseISO(date);
  }

  return toDate(date);
}

export function dateRange(from: Date, to: Date): DateRange {
  return { start: from, end: to };
}

export function dateList(period: DateRange, by: RangeOptions, options?: { excludeEnd?: boolean }): Date[] {
  let dateList: Date[];

  if (!period.start || !period.end || !by) {
    return undefined;
  }

  const start = period.start;
  const end = period.end <= period.start ? addDays(period.end, 1) : period.end;

  if (by === 'hour') {
    const diff = differenceInHours(start, end);
    dateList = reduce(
      range(diff),
      (sum, index) => {
        if (index === 0) {
          sum.push(start);
        } else {
          const currentElement = sum[index - 1];
          sum.push(addHours(currentElement, 1));
        }

        return sum;
      },
      [],
    );
  }

  if (by === 'day') {
    dateList = eachDayOfInterval({ start, end });
  }

  if (by === 'week') {
    dateList = eachWeekOfInterval({ start, end });
  }

  if (options && options.excludeEnd) {
    dateList.pop();
  }

  return dateList;
}

export function rangeList(period: DateRange, by: RangeOptions, options?: { excludeEnd?: boolean }): DateRange[] {
  let dateList: Date[];
  let dateRangeList: DateRange[];

  if (!period.start || !period.end || !by) {
    return undefined;
  }

  const start = period.start;
  const end = period.end <= period.start ? addDays(period.end, 1) : period.end;

  if (by === 'hour') {
    const diff = differenceInHours(end, start);

    dateRangeList = reduce(
      range(diff),
      (sum, index) => {
        if (index === 0) {
          sum.push(dateRange(start, addHours(start, 1)));
        } else {
          const currentElement = sum[index - 1];
          sum.push(dateRange(currentElement.end, addHours(currentElement.end, 1)));
        }

        return sum;
      },
      [],
    );
  }

  if (by === 'day') {
    dateList = eachDayOfInterval({ start, end });
    dateRangeList = dateList.map((startDateTime) => dateRange(startDateTime, addHours(new Date(startDateTime), 1)));
  }

  if (by === 'week') {
    dateList = eachWeekOfInterval({ start, end });
    dateRangeList = dateList.map((startDateTime) => dateRange(startDateTime, addWeeks(new Date(startDateTime), 1)));
  }

  if (options && options.excludeEnd) {
    dateRangeList.pop();
  }

  return dateRangeList;
}

export function rangeMap(
  period: DateRange,
  by: RangeOptions,
  keyFormat: string,
  options?: { excludeEnd?: boolean; step?: number },
) {
  const ranges = rangeList(period, by, options);
  return keyBy(ranges, (dateRangeInstance) => format(dateRangeInstance.start, keyFormat));
}

export function yearList(from: string | number, to: string | number): string[] {
  to = toNumber(to) + 1;

  return range(toNumber(from), to).map((year) => year.toString(10));
}

export const dayList = (minDate: string | Date, maxDate: string | Date): string[] => {
  const dates = [];

  let min = startOfDay(parseDate(minDate));
  const max = startOfDay(parseDate(maxDate));

  while (isBefore(min, max) || isEqual(min, max)) {
    dates.push(format(min, 'yyyy-MM-dd'));
    min = addDays(min, 1);
  }

  return dates;
};

export function format(date: Date | string, formatString: string = 'P'): string {
  if (!isValid(date)) {
    return '';
  }

  const dateLocale = localStorage.getItem('locale') ? dateLocales[localStorage['locale']] : dateLocales[DefaultLocale];

  return formatDate(date as Date, formatString, { locale: dateLocale.format });
}

export function formatRelative(date: Date, baseDate: Date): string {
  if (!isValid(date) || !isValid(baseDate)) {
    return '';
  }

  const dateLocale = localStorage.getItem('locale') ? dateLocales[localStorage['locale']] : dateLocales[DefaultLocale];

  return formatRelativeDate(date as Date, baseDate, { locale: dateLocale.format });
}

export function weekYearStart(year: string | number): Date {
  const yearDate = parse(year.toString(), 'yyyy', new Date());
  return startOfISOWeekYear(addWeeks(yearDate, 1));
}

export function weekYearEnd(year): Date {
  const yearDate = parse(year.toString(), 'yyyy', new Date());
  return endOfISOWeekYear(addWeeks(yearDate, 1));
}

function toNumber(value: string | number): number {
  if (typeof value === 'string') {
    return parseInt(value, 10);
  }
  return value;
}

export function getDayCode(date): string {
  const dateObject = parseDate(date);
  const index = dateObject.getDay();

  return dayCodes[index];
}

export function previousDate(date: Date, period: PeriodType) {
  let start: Date;
  if (period === 'day') {
    start = startOfDay(date);
    return subDays(start, 1);
  }

  if (period === 'week') {
    start = startOfISOWeek(date);
    return subWeeks(start, 1);
  }

  if (period === 'month') {
    start = startOfMonth(date);
    return subMonths(start, 1);
  }

  return undefined;
}

export function nextDate(date: Date, period: PeriodType) {
  let start: Date;
  if (period === 'day') {
    start = startOfDay(date);
    return addDays(start, 1);
  }

  if (period === 'week') {
    start = startOfISOWeek(date);
    return addWeeks(start, 1);
  }

  if (period === 'month') {
    start = startOfMonth(date);
    return addMonths(start, 1);
  }

  return undefined;
}

export interface HasDateString {
  date: string; //date in 2017-12-30 format
}

export interface HasDateRange {
  startDateTime: Date; // native Javascript date object
  endDateTime: Date; // native Javascript date object
}

export interface HasTime {
  starttime: string;
  endtime: string;
}
export interface HasDateStringAndTime extends HasDateString, HasTime {}

export const timeConflictFilter =
  (mainObject: HasTime) =>
  (otherObject: HasTime): boolean =>
    mainObject.starttime < otherObject.endtime && mainObject.endtime > otherObject.starttime;

export function periodFilter(minDate: string, maxDate: string) {
  return (data: HasDateString): boolean => data.date <= maxDate && data.date >= minDate;
}

export function periodDateRangeFilter(minDate: Date, maxDate: Date, exclusivity: boolean = false) {
  return (data): boolean => {
    if (data.hasOwnProperty('startDateTime') && data.hasOwnProperty('endDateTime')) {
      if (exclusivity) {
        if (isSame(data.startDateTime, maxDate) || isSame(data.endDateTime, minDate)) {
          return false;
        }
      }
      return data.startDateTime <= maxDate && data.endDateTime >= minDate;
    } else {
      const startDateTime = parse(data.date + ' ' + data.starttime, 'yyyy-MM-dd HH:mm:ss', new Date());
      let endDateTime = parse(data.date + ' ' + data.endtime, 'yyyy-MM-dd HH:mm:ss', new Date());

      if (endDateTime <= startDateTime) {
        endDateTime = addDays(endDateTime, 1);
      }

      if (exclusivity) {
        if (isSame(startDateTime, maxDate) || isSame(endDateTime, minDate)) {
          return false;
        }
      }

      return startDateTime <= maxDate && endDateTime >= minDate;
    }
  };
}

/**
 * if starttime > endtime add 24 hours to endtime so string comparision works
 * @param start
 * @param end
 */
export function determineEndTimeForComparison(start: string, end: string, allowEquals = true) {
  if (start < end) {
    return end;
  }

  if (allowEquals && start === end) {
    return end;
  }

  const timeParts = end.split(':');
  const hours = timeParts[0];
  const increasedHours = parseInt(hours, 10) + 24;

  timeParts[0] = increasedHours.toString(10);

  return timeParts.join(':');
}

export function maxToday(maxDate: string) {
  const today = format(new Date(), 'yyyy-MM-dd');

  if (today < maxDate) {
    return today;
  }
  return maxDate;
}

export function getPeriod(periodType: PeriodType, date: Date): Period {
  if (periodType === 'day') {
    return {
      start: date,
      end: new Date(date),
    };
  }

  if (periodType === 'week') {
    return getWeek(date);
  }

  return getMonth(date);
}

export function getWeek(date: Date): Period {
  return {
    start: startOfISOWeek(date),
    end: endOfISOWeek(date),
  };
}

export function getMonth(date: Date): Period {
  return {
    start: startOfMonth(date),
    end: endOfMonth(date),
  };
}

export const normalizeTime = (timeString: string) => {
  const [hours, minutes] = timeString.split(':');
  return hours + ':' + minutes + ':00';
};

export function roundToClosestFiveMinutes(date: Date): Date {
  let minutes = getMinutes(date);

  minutes = Math.round(minutes / 5) * 5;

  return setMinutes(new Date(date), minutes);
}

export const convertDateFormatToFnsFormat = (dateFormat: string) => {
  let updatedFormat = dateFormat;
  updatedFormat = updatedFormat.replace('d', 'dd');
  updatedFormat = updatedFormat.replace('m', 'MM');
  updatedFormat = updatedFormat.replace('Y', 'yyyy');
  return updatedFormat;
};

export const timeToDecimal = (t) => {
  const arr = t.split(':');
  const dec = parseInt(String((arr[1] / 6) * 10), 10);

  return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
};

export const removeSecondsFromTime = (time: string) => {
  if (!time) {
    return '';
  }

  if (time.length <= 5) {
    return time;
  }
  return time.replace(/:[^:]*$/, '');
};

export const convertAccountDateFormat = (dateFormat: string) => {
  if (!dateFormat) {
    return 'yyyy-MM-dd';
  }
  return dateFormat.replace('d', 'dd').replace('m', 'MM').replace('Y', 'yyyy');
};

export const convertAccountDateFormatToMoment = (dateFormat: string) => {
  if (!dateFormat) {
    return 'YYYY-MM-DD';
  }
  return dateFormat.replace('d', 'DD').replace('m', 'MM').replace('Y', 'YYYY');
};

export function getTimestamp(dateValue: string) {
  return Math.round(parseDate(dateValue).getTime() / 1000);
}

export const appendSecondsToTimeString = (time) => {
  const hasSeconds = time.length > 5;

  if (!hasSeconds) {
    return time + ':00';
  }

  return time;
};

/**
 * Returns date range with end date offset by 1 day if startime is after endtime
 */
export function getInOrderDateRange({ date, starttime: startTime, endtime: endTime }: HasDateStringAndTime): DateRange {
  const start = parseDate(`${date ?? ''}T${startTime ?? ''}`);
  let end = parseDate(`${date ?? ''}T${endTime ?? ''}`);

  if (end <= start) {
    end = addDays(end, 1);
  }

  return {
    start,
    end,
  };
}
