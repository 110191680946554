import {
  de as deDateLocale,
  enGB as enGBDateLocale,
  es as esDateLocale,
  fr as frDateLocale,
  nl as nlDateLocale,
  pl as plDateLocale,
  ro as roDateLocale,
  sv as svDateLocale,
} from 'date-fns/locale';

export const AvailableLocales = ['nl-NL', 'en-GB', 'de-DE', 'fr-FR', 'es-ES', 'pl-PL', 'sv-SE', 'ro-RO'];
export const DefaultLocale = 'en-GB';

interface Locale {
  [locale: string]: {
    format: LocaleAliased;
  };
}

export const languageNames = {
  en: 'English',
  nl: 'Nederlands',
  de: 'Deutsch',
  es: 'Español',
  fr: 'Français',
  pl: 'Polski',
  sv: 'Svenska',
  ro: 'Română',
};

export enum LocaleLanguage {
  DUTCH = 'nl-NL',
  ENGLISH = 'en-GB',
  GERMAN = 'de-DE',
  FRENCH = 'fr-FR',
  SPANISH = 'es-ES',
  POLISH = 'pl-PL',
  SWEDISH = 'sv-SE',
  ROMANIAN = 'ro-RO',
}

export const dateLocales: Locale = {
  'en-GB': { format: enGBDateLocale },
  'nl-NL': { format: nlDateLocale },
  'de-DE': { format: deDateLocale },
  'fr-FR': { format: frDateLocale },
  'es-ES': { format: esDateLocale },
  'pl-PL': { format: plDateLocale },
  'sv-SE': { format: svDateLocale },
  'ro-RO': { format: roDateLocale },
};

export const LANGUAGE_LOCALE_MAP = {
  en: 'en-GB',
  nl: 'nl-NL',
  de: 'de-DE',
  fr: 'fr-FR',
  es: 'es-ES',
  pl: 'pl-PL',
  sv: 'sv-SE',
  ro: 'ro-RO',
};

export const availableLocaleRegExpPattern = '^(nl-NL|en-GB|de-DE|fr-FR|es-ES|pl-PL|sv-SE|ro-RO)$';

export const parseLocaleNumber = (value: string, locale: string, options = {}) => {
  const parts = new Intl.NumberFormat(locale, options).formatToParts(12345.6);
  const numerals = [...new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)].reverse();
  const index = new Map(numerals.map((d, i) => [d, i]));
  const group = new RegExp(`[${parts.find((d) => d.type === 'group').value}]`, 'g');
  const decimal = new RegExp(`[${parts.find((d) => d.type === 'decimal')?.value}]`);
  const numeral = new RegExp(`[${numerals.join('')}]`, 'g');
  const _index: any = (d) => index.get(d);

  return (value = value.trim().replace(group, '').replace(decimal, '.').replace(numeral, _index)) ? +value : NaN;
};

export const FallBackDateFormat = 'YYYY-MM-DD';
const dateFormats = {
  'en-GB': enGBDateLocale.formatLong.date({ width: 'short' }),
  'nl-NL': nlDateLocale.formatLong.date({ width: 'short' }),
  'de-DE': deDateLocale.formatLong.date({ width: 'short' }),
  'fr-FR': frDateLocale.formatLong.date({ width: 'short' }),
  'es-ES': esDateLocale.formatLong.date({ width: 'short' }),
  'pl-PL': plDateLocale.formatLong.date({ width: 'short' }),
  'sv-SE': svDateLocale.formatLong.date({ width: 'short' }),
  'ro-RO': roDateLocale.formatLong.date({ width: 'short' }),
};

export const getMomentDateFormat = (locale: string) => convertDateFnsToMoment(dateFormats[locale]);
export const getPrimeNGDateFormat = (locale: string) => convertDateFnsToPrimeNG(dateFormats[locale]);

const convertDateFnsToMoment = (format: string) => {
  const upperCase = format.toLocaleUpperCase();

  return upperCase.replace('YYYY', 'Y').replace('Y', 'YYYY');
};

const convertDateFnsToPrimeNG = (format: string) => {
  const upperCase = format.toLocaleLowerCase();

  return upperCase.replace(/y{1,}/g, 'yy');
};
