import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultLocale } from '@app/+authenticated/shared/locale/locale.helper';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import omit from 'lodash-es/omit';
import { ConnectableObservable, Observable, concat as observableConcat, empty as observableEmpty, Subject } from 'rxjs';
import { finalize, map, publishReplay, scan } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AppState } from '../reducers/index';
import { UnsafeAction as Action, UnsafeAction } from '../reducers/interfaces';

@Injectable()
export class ApiGateway {
  private pending = new Subject<number>();
  private pendingGet = new Subject<number>();

  private inFlight$: ConnectableObservable<number>;
  private inFlightGet$: ConnectableObservable<number>;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.inFlight$ = this.pending.pipe(
      scan((acc: number, value: number): number => acc + value, 0),
      publishReplay(1),
    ) as ConnectableObservable<number>;

    this.inFlightGet$ = this.pendingGet.pipe(
      scan((acc: number, value: number): number => acc + value, 0),
      publishReplay(1),
    ) as ConnectableObservable<number>;

    this.inFlight$.connect();
    this.inFlightGet$.connect();
  }

  /**
   * Performs any type of http request.
   * @param url
   * @param options
   */
  request(requestMethod: string, url: string, options, dispatchStart: UnsafeAction): Observable<any> {
    url = this.getFullUrl(url);
    const meta = options && options.meta;
    options = this.getRequestOptions(options);

    const request = this.http
      .request(requestMethod, url, options)
      .pipe(map((response) => (meta ? response : this.mapData(response))));

    return observableConcat(this.beforeStart(dispatchStart), request.pipe(finalize(() => this.onComplete())));
  }

  beforeStart(dispatchStart?: Action) {
    return observableEmpty().pipe(
      finalize(() => {
        this.pending.next(1);

        if (dispatchStart) {
          this.store.dispatch(dispatchStart);
        }
      }),
    );
  }

  onComplete() {
    this.pending.next(-1);
  }

  /**
   * Performs a request with `get` http method.
   * @param url
   * @param options
   * @returns {Observable<>}
   */
  public get(url: string, options?, dispatchStart?: Action, countAsPending = true): Observable<any> {
    const request = this.request('GET', url, options, dispatchStart);

    if (!countAsPending) {
      return request;
    }
    return observableConcat(
      observableEmpty().pipe(finalize(() => this.pendingGet.next(1))),
      request.pipe(finalize(() => this.pendingGet.next(-1))),
    );
  }

  /**
   * Performs a request with `post` http method.
   * @param url
   * @param body
   * @param options
   * @returns {Observable<>}
   */
  post(url: string, body: any, options?, dispatchStart?: Action): Observable<any> {
    options = options || {};
    options.body = body;

    return this.request('POST', url, options, dispatchStart);
  }

  /**
   * Performs a request with `put` http method.
   * @param url
   * @param body
   * @param options
   * @returns {Observable<>}
   */
  put(url: string, body: string | {}, options?, dispatchStart?: Action): Observable<any> {
    options = options || {};
    options.body = body;

    return this.request('PUT', url, options, dispatchStart);
  }

  /**
   * Performs a request with `patch` http method.
   * @param url
   * @param body
   * @param options
   * @returns {Observable<>}
   */
  patch(url: string, body: string | {}, options?, dispatchStart?: Action): Observable<any> {
    options = options || {};
    options.body = body;

    return this.request('PATCH', url, options, dispatchStart);
  }

  /**
   * Performs a request with `delete` http method.
   * @param url
   * @param options
   * @returns {Observable<>}
   */
  delete(url: string, options?, dispatchStart?: Action, body?: string | {}): Observable<any> {
    options = options || {};
    options.body = body;

    return this.request('DELETE', url, options, dispatchStart);
  }

  getPendingRequestCounter() {
    return this.inFlight$;
  }

  getPendingGetRequestCounter() {
    return this.inFlightGet$;
  }

  /**
   * download a file via the api
   * @param {string} url
   * @param {string|undefined} fileName If fileName is not set, try to get it from the response
   */
  download(url: string, fileName?: string) {
    url = this.getFullUrl(url);
    const options = this.getRequestOptions();
    options['responseType'] = 'blob';
    options['observe'] = 'response';

    const request = this.http.request('GET', url, options).pipe(
      map((res: any) => {
        const blob = new Blob([res.body], {
          type: res.headers.get('Content-Type'),
        });
        if (fileName === undefined) {
          // No filename provided.  Parse it from the response header, if it is there.
          const contentDispositionHeader = res.headers.get('Content-Disposition');
          if (contentDispositionHeader !== null) {
            fileName = new URLSearchParams(
              contentDispositionHeader
                .replaceAll(/; +/g, '&') // Replace semicolons with ampersand to conform to the URL params standard instead.
                .replaceAll('"', ''), // Remove any quotes from values.
            ).get('filename');

            // If there was no filename provided in the content-disposition, filename will be null
            // But saveAs will start complaining about nulls.
            if (fileName === null) {
              fileName = undefined;
            }
          }
        }

        saveAs(blob, fileName);

        return res;
      }),
    );

    return observableConcat(this.beforeStart(), request.pipe(finalize(() => this.onComplete())));
  }

  /**
   * Request options.
   * @param options
   * @returns}
   */
  private getRequestOptions(options?) {
    options = omit(options || {}, 'meta');

    let headers = new HttpHeaders();

    headers = headers.append('X-App-Type', 'Web App');
    headers = headers.append('X-App-Version', environment.version);
    headers = headers.append('X-App-Locale', localStorage.getItem('locale') ?? DefaultLocale);

    if (options.headers) {
      headers = headers.append('ignore-meta-message', options.headers?.ignoreMetaMessage.toString());
    }

    if (!headers.has('Accept')) {
      headers = headers.append('Accept', 'application/json');
    }

    // Set reportProgress to false because errors that return html were incorrectly returned as ProgressEvent
    if (options['reportProgress'] === undefined) {
      options = { ...options, reportProgress: false };
    }

    return { ...options, headers };
  }

  /**
   * Build API url.
   * @param url
   * @returns {string}
   */
  public getFullUrl(url: string): string {
    if (url.indexOf('http') !== -1) {
      return url;
    }

    return '/api/' + url;
  }

  private mapData(jsonResponse) {
    if (jsonResponse?.data !== undefined) {
      return jsonResponse.data;
    }

    return jsonResponse;
  }
}
